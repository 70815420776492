import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChartNetwork } from "@fortawesome/pro-regular-svg-icons";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
import GoingOfflineSvg from "../images/undraw/undraw_going_offline_ihag.svg";
import LogoCloud from "../components/LogoCloud";

const OfflineCapabilitiesPage = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="Offline Capabilities" />
          <Card
            visible={cardVisible}
            header="Offline Capabilities for Poor Network Conditions"
            previousUrl="/audience-interactions"
            previousLabel="Audience Interactions"
            nextUrl="/resource-performance-management"
            nextLabel="Resource and Performance Management"
          >
            <p className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5">
              Real world usage of distributed software always entails the
              possibility of network failure. Using a variety of techniques,
              offline robustness can be maximized.
            </p>
            <ul className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5 list-disc list-inside">
              <li>
                Progressive Web Apps - service workers for precaching and
                offline rendering of the application shell
              </li>
              <li>Optimized protocols and technology like MQTT and Erlang</li>
              <li>
                Aggressive local caching and preloading wherever possible -
                here&apos;s a{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-magenta"
                  href="https://www.npmjs.com/package/express-asset-file-cache-middleware"
                >
                  small npm package
                </a>{" "}
                I wrote. (and{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-magenta"
                  href="https://link.medium.com/uoh9fYcDf7"
                >
                  here&apos;s an article explaining the why and how in more
                  detail
                </a>
                .)
              </li>
            </ul>
            <div className="grid gap-6 grid-cols-1">
              <figure className="w-full">
                <img alt="content" src={GoingOfflineSvg} />
              </figure>
            </div>
          </Card>
          <LogoCloud
            logos={[
              {
                name: "kreativfarm",
                url: "http://kreativ.farm/",
              },
              { name: "zone", url: "https://zonemedia.at/" },
            ]}
          />
        </>
      )}
    </Layout>
  );
};

export default OfflineCapabilitiesPage;
